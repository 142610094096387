<template>
  <modal
    :show="modalStatus"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-xl"
    @close="onModalClosed"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white pb-5"
      body-classes="px-lg-5 py-lg-5"
      class="border-0"
    >
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="onSaved" class="position-relative">
          <base-loader v-if="$wait.is('saveAssignments')" />
          <ValidationProvider
            :name="t('center')"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="form-group">
              <label class="form-control-label"> {{ t("center") }} * </label>
              <select
                class="form-control"
                v-model="data.center_id"
                @change="setCenter()"
              >
                <option
                  :value="center.id"
                  v-for="(center, centerKey) in centers"
                  :key="centerKey"
                >
                  {{ center.name }}
                </option>
              </select>
              <base-alert type="warning" v-if="errors[0]" class="mt-2">
                {{ errors[0] }}
              </base-alert>
            </div>
          </ValidationProvider>
          <div v-if="data.center_id">
            <tabs fill class="flex-column flex-md-row">
              <tab-pane :title="t('center_randomizations')">
                <table
                  class="
                    table
                    tablesorter
                    table
                    align-items-center
                    table-bordered
                  "
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>{{ t("randomization_treatment") }}</th>
                      <th>{{ t("randomization_randomization_no") }}</th>
                      <th>{{ t("randomization_lot_number") }}</th>
                    </tr>
                  </thead>
                  <tbody class="list">
                    <tr
                      :class="{ 'row-green': centerItem.assigned }"
                      v-for="(centerItem, keyCenterItem) in centerRandomization"
                      :key="keyCenterItem"
                    >
                      <td>
                        <base-checkbox
                          :disabled="centerItem.assigned"
                          :dataValue="centerItem.id.toString()"
                          v-model="data.center_randomizations"
                        >
                        </base-checkbox>
                      </td>
                      <td>{{ centerItem.treatment }}</td>
                      <td>{{ centerItem.randomization_no }}</td>
                      <td>{{ centerItem.lot_number }}</td>
                    </tr>
                  </tbody>
                </table>
              </tab-pane>
              <tab-pane :title="t('unassigned_randomizations')">
                <table
                  class="
                    table
                    tablesorter
                    table
                    align-items-center
                    table-bordered
                  "
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>{{ t("randomization_treatment") }}</th>
                      <th>{{ t("randomization_randomization_no") }}</th>
                      <th>{{ t("randomization_lot_number") }}</th>
                    </tr>
                  </thead>
                  <tbody class="list">
                    <tr
                      v-for="(
                        unassignedItem, keyunassignedItem
                      ) in emptyRandomization"
                      :key="keyunassignedItem"
                    >
                      <td>
                        <base-checkbox
                          v-model="data.center_randomizations"
                          :dataValue="unassignedItem.id.toString()"
                        >
                        </base-checkbox>
                      </td>
                      <td>{{ unassignedItem.treatment }}</td>
                      <td>{{ unassignedItem.randomization_no }}</td>
                      <td>{{ unassignedItem.lot_number }}</td>
                    </tr>
                  </tbody>
                </table>
              </tab-pane>
            </tabs>
          </div>
          <div class="d-flex justify-content-between">
            <base-button
              outline
              type="default"
              class="my-4"
              @click="onModalClosed"
            >
              {{ t("button_cancel") }}
            </base-button>
            <base-button
              type="success"
              class="my-4"
              nativeType="submit"
              :disabled="invalid || $wait.is('saveAssignments')"
            >
              {{ t("button_save") }}
            </base-button>
          </div>
        </form>
      </ValidationObserver>
    </card>
  </modal>
</template>
<script>
export default {
  name: "assignment-modal",
  data() {
    return {
      data: {
        center_id: null,
        center_randomizations: [],
      },
    };
  },
  props: {
    modalStatus: {
      type: Boolean,
      required: true,
    },
    randomization: {
      type: Array,
      default: () => [],
    },
    centers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    centerRandomization() {
      return this.randomization.filter(
        (_r) => this.data.center_id && _r.center_id === this.data.center_id
      );
    },
    emptyRandomization() {
      return this.randomization.filter((_r) => _r.center_id === null);
    },
  },
  watch: {
    modalStatus: function (status) {
      if (status && !!this.id) {
        this.getCenter();
      }
    },
  },
  methods: {
    onModalClosed() {
      this.data.center_id = null;
      this.$emit("update:modalStatus", false);
    },

    setCenter() {
      this.data.center_randomizations = this.centerRandomization.map((_r) =>
        _r.id.toString()
      );
    },

    async onSaved() {
      try {
        this.$wait.start("saveAssignments");
        const randomization = await this.$store.dispatch(
          "randomization/UPDATE_CENTER_RANDOMIZATION",
          this.data
        );
        this.showSuccess(this.t("message_saved"));
        this.$emit("updated", randomization);
        this.onModalClosed();
      } catch (err) {
        this.showError(err);
      }
      this.$wait.end("saveAssignments");
    },
  },
  created() {},
};
</script>
<style lang="scss">
.row-green {
  background-color: #2dce89;
  td {
    color: #fff !important;
  }
}
</style>

