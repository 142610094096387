var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"header pb-8 pt-5 pt-lg-8 d-flex align-items-center"},[_c('span',{staticClass:"mask bg-gradient-success opacity-8"}),_c('div',{staticClass:"container-fluid d-flex align-items-center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"display-3 text-white"},[_vm._v(_vm._s(_vm.t("randomization_name")))])])])])]),_c('div',{staticClass:"container-fluid mt--7"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"shadow":"","type":"secondary"}},[(_vm.$wait.is('fetchRandomization') || _vm.$wait.is('deleteCenter'))?_c('base-loader'):_vm._e(),_c('div',{staticClass:"bg-white border-0",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12 text-right"},[_c('base-button',{attrs:{"outline":"","type":"primary","icon":"ni ni-app"},on:{"click":function($event){return _vm.openAssignmentModal()}}},[_vm._v(" "+_vm._s(_vm.t("randomization_assignment"))+" ")])],1)])]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.randomization,"search-options":{
              enabled: true,
              skipDiacritics: false,
            },"pagination-options":{
              enabled: true,
              mode: 'records',
              perPage: 30,
              position: 'bottom',
              nextLabel: _vm.t('next'),
              prevLabel: _vm.t('prev'),
              rowsPerPageLabel: _vm.t('per_page'),
              ofLabel: 'of',
              pageLabel: _vm.t('page'),
              allLabel: _vm.t('all'),
            },"fixed-header":true,"line-numbers":true,"row-style-class":_vm.rowStyleClassFn}})],1)],1)])]),_c('assignment-modal',{attrs:{"modalStatus":_vm.assignmentModal,"randomization":_vm.randomization,"centers":_vm.centers},on:{"update:modalStatus":function($event){_vm.assignmentModal=$event},"update:modal-status":function($event){_vm.assignmentModal=$event},"updated":function($event){return _vm.randomizationUpdated($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }