<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-12">
            <h1 class="display-3 text-white">{{ t("randomization_name") }}</h1>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-12">
          <card shadow type="secondary">
            <base-loader
              v-if="$wait.is('fetchRandomization') || $wait.is('deleteCenter')"
            />
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-12 text-right">
                  <base-button
                    outline
                    type="primary"
                    icon="ni ni-app"
                    @click="openAssignmentModal()"
                  >
                    {{ t("randomization_assignment") }}
                  </base-button>
                </div>
              </div>
            </div>
            <vue-good-table
              :columns="columns"
              :rows="randomization"
              :search-options="{
                enabled: true,
                skipDiacritics: false,
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 30,
                position: 'bottom',
                nextLabel: t('next'),
                prevLabel: t('prev'),
                rowsPerPageLabel: t('per_page'),
                ofLabel: 'of',
                pageLabel: t('page'),
                allLabel: t('all'),
              }"
              :fixed-header="true"
              :line-numbers="true"
              :row-style-class="rowStyleClassFn"
            >
            </vue-good-table>
          </card>
        </div>
      </div>
    </div>
    <assignment-modal
      :modalStatus.sync="assignmentModal"
      :randomization="randomization"
      :centers="centers"
      @updated="randomizationUpdated($event)"
    />
  </div>
</template>
<script>
import AssignmentModal from "./AssignmentModal/index.vue";

export default {
  name: "randomization",
  components: {
    AssignmentModal,
  },
  data() {
    return {
      columns: [
        {
          label: this.t("randomization_group"),
          field: "group",
        },
        {
          label: this.t("randomization_treatment_arm"),
          field: "treatment_arm",
        },
        {
          label: this.t("randomization_treatment"),
          field: "treatment",
        },
        {
          label: this.t("randomization_randomization_no"),
          field: "randomization_no",
        },
        {
          label: this.t("randomization_lot_number"),
          field: "lot_number",
        },
        {
          label: this.t("center"),
          field: "center.name",
        },
        {
          label: this.t("randomization_assigned"),
          field: "assigned",
          sortFn: (x, y) => {
            return x < y ? -1 : x > y ? 1 : 0;
          },
          formatFn: (val) => {
            return val ? this.t("assigned") : this.t("pending");
          },
        },
      ],
      randomization: [],
      centers: [],
      assignmentModal: false,
    };
  },
  methods: {
    getRandomization() {
      this.$wait.end("fetchRandomization");
      this.$store
        .dispatch("randomization/FETCH_RANDOMIZATION")
        .then((randomization) => {
          this.randomization = randomization;
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("fetchRandomization");
        });
    },
    getCenters() {
      this.$wait.end("fetchCenters");
      this.$store
        .dispatch("center/FETCH_CENTERS")
        .then((centers) => {
          this.centers = centers;
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("fetchCenters");
        });
    },
    openAssignmentModal() {
      this.assignmentModal = true;
    },
    randomizationUpdated(randomization) {
      this.randomization = randomization;
    },
    rowStyleClassFn(row) {
      return row.assigned ? "row-green" : "";
    },
  },
  created() {
    this.getRandomization();
    this.getCenters();
  },
};
</script>
<style lang="scss">
.row-green {
  background-color: #2dce89;
  td {
    color: #fff !important;
  }
}
</style>
